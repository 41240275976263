import { Col, Input, Row } from 'antd'
import React from 'react'
import { Form } from 'react-router-dom'
import DesaPage_ from "../../AdminDashboard/InputDesa";

const DesaPage = () => {
  return (
    <>
     <DesaPage_/>
    </>
  )
}

export default DesaPage
